#logo {
	display: flex;
	align-items: center;
	#status {
		margin: 0px 10px;
		width: 12px;
		height: 12px;
		border-radius: 6px;
		background-image: linear-gradient(180deg, #B4EC51 0%, #429321 100%);
		box-shadow: 0 0 7px 2px rgba(126,211,33,0.66);
	}
	#logo-text {
		margin-left: 8px;
		font-size: 18px;
	}
}